<template>
  <div>
    <div class="g-head">
      <div class="wal">
        <a href="" class="logo logo-white"
          ><img src="@/assets/image/logo.png" alt=""
        /></a>
        <a href="" class="logo logo-color"
          ><img src="@/assets/image/logo269.png" alt=""
        /></a>
        <div class="side">
          <div class="tel">
            <div class="name">服务热线</div>
            <em>0757-81068092</em>
          </div>
          <div class="btns">
            <a href="https://link.yzysaas.com">登录</a><em>丨</em
            ><a href="https://link.yzysaas.com">注册</a>
          </div>
        </div>
      </div>
    </div>

    <div class="index-head">
      <video src="https://oss.yzysaas.com/system/video/yzy.mp4" muted loop autoplay></video>
      <div class="wal">
        <h2>运智易物流供应链云平台</h2>
        <div class="msg">新一代港口物流数字协作方式</div>
        <div class="btns">
          <a href="https://link.yzysaas.com" class="btn-1 on">立即体验</a>
          <a href="javascript:" @click="videoShow = true" class="btn-2"><img src="@/assets/image/img17.png" alt=""><em>观看视频</em></a>
        </div>
        <div class="tips"># 一站式港口物流协作云平台，致力于帮助司机省事、员工省心、老板省钱、货主满意 # </div>
      </div>
    </div>

    <div class="video-layer" v-if="videoShow" @click.self="videoShow = false">
      <div class="container">
        <video src="https://oss.yzysaas.com/system/video/yzyfull.mp4" controls ref="video"></video>
      </div>
    </div>

    <div class="index-1">
      <div class="wal">
        <ul>
          <li class="wow animate__animated animate__slideInUp">
            <div class="ico">
              <img src="@/assets/image/img60_1.png" alt="" />
            </div>
            <div class="name">专业</div>
            <p>15年港口物流行业经验</p>
          </li>
          <li class="wow animate__animated animate__slideInUp">
            <div class="ico">
              <img src="@/assets/image/img60_2.png" alt="" />
            </div>
            <div class="name">免费</div>
            <p>在线开箱即用免费体验</p>
          </li>
          <li class="wow animate__animated animate__slideInUp">
            <div class="ico">
              <img src="@/assets/image/img60_3.png" alt="" />
            </div>
            <div class="name">高效</div>
            <p>上下游全渠道智能云协同</p>
          </li>
          <li class="wow animate__animated animate__slideInUp">
            <div class="ico">
              <img src="@/assets/image/img60_4.png" alt="" />
            </div>
            <div class="name">可靠</div>
            <p>10万票拖车报关作业验证</p>
          </li>
        </ul>
      </div>
    </div>

    <div class="index-2">
      <div class="wal">
        <div class="index-title wow animate__animated animate__slideInUp">
          <h2>传统物流作业方式普遍面临的困扰</h2>
          <p>越琐碎的业务，越需要协作，选择好用的工具与努力更重要</p>
        </div>
        <div class="list list-1 wow animate__animated animate__slideInUp">
          <h3>货主企业</h3>
          <ul>
            <li>出货单据信息多 / 数据整理难度高</li>
            <li>业务需求不稳定 / 运输资源协调难</li>
            <li>信息反馈时效差 / 出货过程焦虑重</li>
            <li>行业资源多乱杂 / 服务技术水平低</li>
            <li>业务高峰事务多 / 多方沟通成本高</li>
          </ul>
        </div>
        <div class="list list-2 wow animate__animated animate__slideInUp">
          <h3>物流企业</h3>
          <ul>
            <li>作业管理繁杂乱 / 员工心理负担重</li>
            <li>碎片信息轰炸多 / 数据无痕追溯难</li>
            <li>作业过程规范差 / 工作凌乱效率低</li>
            <li>服务角色参与多 / 作业协作难度高</li>
            <li>作业过程规范差 / 服务质量提升难</li>
          </ul>
        </div>
      </div>
    </div>

    <div class="index-3">
      <div class="wal">
        <div class="index-title wow animate__animated animate__slideInUp">
          <h2>使用运智易供应链云平台作业带来的收益</h2>
          <p>公司、员工、客户、供应商、司机多方受益</p>
        </div>
        <div class="list wow animate__animated animate__slideInUp">
          <h3>货主</h3>
          <ul>
            <li>
              <div class="name">心理负担降低</div>
              <div class="msg down">50%</div>
            </li>
            <li>
              <div class="name">人工成本降低</div>
              <div class="msg down">20%</div>
            </li>
            <li>
              <div class="name">作业效率提升</div>
              <div class="msg up">30%</div>
            </li>
            <li>
              <div class="name">员工好感提高</div>
              <div class="msg up">70%</div>
            </li>
          </ul>
        </div>
        <div class="list wow animate__animated animate__slideInUp">
          <h3>物流</h3>
          <ul>
            <li>
              <div class="name">工作效率提升</div>
              <div class="msg up">60%</div>
            </li>
            <li>
              <div class="name">人工成本降低</div>
              <div class="msg down">40%</div>
            </li>
            <li>
              <div class="name">服务质量提高</div>
              <div class="msg up">30%</div>
            </li>
            <li>
              <div class="name">客户好感提升</div>
              <div class="msg up">50%</div>
            </li>
          </ul>
        </div>
        <div class="list wow animate__animated animate__slideInUp">
          <h3>司机</h3>
          <ul>
            <li>
              <div class="name">电话轰炸降低</div>
              <div class="msg down">90%</div>
            </li>
            <li>
              <div class="name">工作效率提升</div>
              <div class="msg up">40%</div>
            </li>
            <li>
              <div class="name">服务质量提升</div>
              <div class="msg up">30%</div>
            </li>
            <li>
              <div class="name">客户好感提升</div>
              <div class="msg up">50%</div>
            </li>
          </ul>
        </div>
      </div>
    </div>

    <div class="index-4">
      <div class="index-title wow animate__animated animate__slideInUp">
        <h2>新一代港口物流供应链云平台的特点</h2>
        <p>上手快、功能强、账单准、报表全、零成本</p>
      </div>
      <ul>
        <li class="wow animate__animated animate__slideInUp">
          <div class="wal">
            <div class="img">
              <img src="@/assets/image/img685.png" alt="" />
            </div>
            <div class="container">
              <h5>多端同步随时作业</h5>
              <div class="content">
                同时支持电脑、平板、手机，数据实时同步，一键登录、随时作业。
              </div>
            </div>
          </div>
        </li>
        <li class="wow animate__animated animate__slideInUp">
          <div class="wal">
            <div class="img">
              <img src="@/assets/image/img631.png" alt="" />
            </div>
            <div class="container">
              <h5>物流企业零成本数字化转型升级</h5>
              <div class="content">
                1分钟在线开箱即用，专属成功经理，全程辅助企业启动数字化提升企业整体竞争力。
              </div>
            </div>
          </div>
        </li>
        <li class="wow animate__animated animate__slideInUp">
          <div class="wal">
            <div class="img">
              <img src="@/assets/image/img511.png" alt="" />
            </div>
            <div class="container">
              <h5>多方协同缓解单一节点压力</h5>
              <div class="content">
                全链条上货主、车队、司机多方线协同作业,降低单一角色负担。
              </div>
            </div>
          </div>
        </li>
        <li class="wow animate__animated animate__slideInUp">
          <div class="wal">
            <div class="img">
              <img src="@/assets/image/img586.png" alt="" />
            </div>
            <div class="container">
              <h5>作业即时沟通信息实时共享</h5>
              <div class="content">
                基于订单作业成员组内沟通，实时反馈,文字、语音、图片、文件共享、在线预览、手机转发、实时提醒，信息传递快，疏漏情况低。
              </div>
            </div>
          </div>
        </li>
        <li class="wow animate__animated animate__slideInUp">
          <div class="wal">
            <div class="img">
              <img src="@/assets/image/img619.png" alt="" />
            </div>
            <div class="container">
              <h5>全程可视化跟踪，规范作业流程</h5>
              <div class="content">
                三跨机制,跨企业、跨部门、跨角色,作业规范，全责清晰、过程可视、数据可溯。。
              </div>
            </div>
          </div>
        </li>
        <li class="wow animate__animated animate__slideInUp">
          <div class="wal">
            <div class="img">
              <img src="@/assets/image/img614.png" alt="" />
            </div>
            <div class="container">
              <h5>账单数据自动生成</h5>
              <div class="content">
                单票作业完成自动生成账单数据、一键出账、一键对账、一键销账、一键开票，全程可视化汇总，杜绝漏单、错单，重复投入。
              </div>
            </div>
          </div>
        </li>
      </ul>
    </div>

    <div class="index-5">
      <div class="wal">
        <div class="index-title wow animate__animated animate__slideInUp">
          <h2>用户心声,好评如潮</h2>
          <p>用户的满意是我们熬夜的动力</p>
        </div>
        <div class="list wow animate__animated animate__slideInUp">
          <ul class="swiper-wrapper">
            <li class="swiper-slide">
              <div class="content">
                很有档次,感觉和其他物流公司不一样,品味对得上;<br />
                能看见订单的各个过程节点,能随时随地把控进度,比较放心;<br />
                柜号、封条、过磅都能自行查看,方便!<br />
                报关的进度反馈很实用,报关单下载也很简单,很久以后想找这份文档也容易!
              </div>
              <div class="name">—— 张总 深圳市庐轩供应链有限公司</div>
            </li>
            <li class="swiper-slide">
              <div class="content">
                很有档次,感觉和其他物流公司不一样,品味对得上;<br />
                能看见订单的各个过程节点,能随时随地把控进度,比较放心;<br />
                柜号、封条、过磅都能自行查看,方便!<br />
                报关的进度反馈很实用,报关单下载也很简单,很久以后想找这份文档也容易!
              </div>
              <div class="name">—— 张总 深圳市庐轩供应链有限公司</div>
            </li>
            <li class="swiper-slide">
              <div class="content">
                很有档次,感觉和其他物流公司不一样,品味对得上;<br />
                能看见订单的各个过程节点,能随时随地把控进度,比较放心;<br />
                柜号、封条、过磅都能自行查看,方便!<br />
                报关的进度反馈很实用,报关单下载也很简单,很久以后想找这份文档也容易!
              </div>
              <div class="name">—— 张总 深圳市庐轩供应链有限公司</div>
            </li>
          </ul>
          <div class="swiper-pagination"></div>
          <div class="arrow">
            <a href="" class="prev"></a>
            <a href="" class="next"></a>
          </div>
        </div>
      </div>
    </div>

    <div class="index-6">
      <div class="wal">
        <div class="index-title wow animate__animated animate__slideInUp">
          <h2><em>1000+</em> 货主/物流企业入驻</h2>
          <p>物流行业数字化大势所趋，天下武功唯快不破</p>
        </div>
        <div class="list">
          <ul class="wow animate__animated animate__slideInUp">
            <li>
              <div class="img">
                <img src="@/assets/image/img151_1.png" alt="" />
              </div>
            </li>
            <li>
              <div class="img">
                <img src="@/assets/image/img151_2.png" alt="" />
              </div>
            </li>
            <li>
              <div class="img">
                <img src="@/assets/image/img151_3.png" alt="" />
              </div>
            </li>
            <li>
              <div class="img">
                <img src="@/assets/image/img151_5.png" alt="" />
              </div>
            </li>
            <li>
              <div class="img">
                <img src="@/assets/image/img151_6.png" alt="" />
              </div>
            </li>
          </ul>
          <ul class="wow animate__animated animate__slideInUp">
            <li>
              <div class="img">
                <img src="@/assets/image/img151_7.png" alt="" />
              </div>
            </li>
            <li>
              <div class="img">
                <img src="@/assets/image/img151_8.png" alt="" />
              </div>
            </li>
            <li>
              <div class="img">
                <img src="@/assets/image/img151_9.png" alt="" />
              </div>
            </li>
            <li>
              <div class="img">
                <img src="@/assets/image/img151_10.png" alt="" />
              </div>
            </li>
            <li>
              <div class="img">
                <img src="@/assets/image/img151_11.png" alt="" />
              </div>
            </li>
            <li>
              <div class="img">
                <img src="@/assets/image/img151_12.png" alt="" />
              </div>
            </li>
          </ul>
          <ul class="wow animate__animated animate__slideInUp">
            <li>
              <div class="img">
                <img src="@/assets/image/img151_13.png" alt="" />
              </div>
            </li>
            <li>
              <div class="img">
                <img src="@/assets/image/img151_14.png" alt="" />
              </div>
            </li>
            <li>
              <div class="img">
                <img src="@/assets/image/img151_15.png" alt="" />
              </div>
            </li>
            <li>
              <div class="img">
                <img src="@/assets/image/img151_16.png" alt="" />
              </div>
            </li>
            <li>
              <div class="img">
                <img src="@/assets/image/img151_17.png" alt="" />
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>

    <div class="index-7">
      <div class="wal">
        <div class="index-title wow animate__animated animate__slideInUp">
          <h2>两种模式，按需选择，随时升级</h2>
          <p>每一分钱都要花在刀刃上</p>
        </div>
        <div class="list list-1 wow animate__animated animate__slideInUp">
          <div class="title">
            <h3>体验版</h3>
            <div>适用于企业经营快速转型</div>
          </div>
          <div class="cost">
            <h3>免费体验</h3>
          </div>
          <!-- <div class="btn"><a href="">立即体验</a></div> -->
          <div class="sub-title"><em>产品规格</em></div>
          <ul>
            <li>
              <div class="name">订单数量</div>
              <div class="value">30</div>
            </li>
            <li>
              <div class="name">客户数量</div>
              <div class="value orange">不限</div>
            </li>
            <li>
              <div class="name">供应商数量</div>
              <div class="value orange">不限</div>
            </li>
            <li>
              <div class="name">司机数量</div>
              <div class="value orange">不限</div>
            </li>
            <li>
              <div class="name">账户数量</div>
              <div class="value orange">不限</div>
            </li>
          </ul>
          <div class="sub-title"><em>平台功能</em></div>
          <ul>
            <li>
              <div class="name">货主端含电脑版和手机版</div>
              <div class="ico">
                <img src="@/assets/image/img16_1.png" alt="" />
              </div>
            </li>
            <li>
              <div class="name">物流端含电脑版和手机版</div>
              <div class="ico">
                <img src="@/assets/image/img16_1.png" alt="" />
              </div>
            </li>
            <li>
              <div class="name">司机端含手机版</div>
              <div class="ico">
                <img src="@/assets/image/img16_1.png" alt="" />
              </div>
            </li>
          </ul>
          <div class="sub-title"><em>产品服务</em></div>
          <ul>
            <li>
              <div class="name">业务顾问</div>
              <div class="ico">
                <img src="@/assets/image/img16_1.png" alt="" />
              </div>
            </li>
            <li>
              <div class="name">专属客服</div>
              <div class="ico">
                <img src="@/assets/image/img16_1.png" alt="" />
              </div>
            </li>
            <li>
              <div class="name">视频教程</div>
              <div class="ico">
                <img src="@/assets/image/img16_1.png" alt="" />
              </div>
            </li>
            <li>
              <div class="name">在线帮助</div>
              <div class="ico">
                <img src="@/assets/image/img16_1.png" alt="" />
              </div>
            </li>
            <li>
              <div class="name">成功经理</div>
              <div class="ico">
                <img src="@/assets/image/img16_2.png" alt="" />
              </div>
            </li>
          </ul>
        </div>
        <div class="list list-2 wow animate__animated animate__slideInUp">
          <div class="title">
            <h3>企业版</h3>
            <div>适用于中小物流企业发展</div>
          </div>
          <div class="cost">
            <!--            <div><em>只需</em><b>998</b><em>元/年/账号</em></div>
            <p>低至2.7元/日</p>-->
            <h3>按量付费</h3>
          </div>
          <!-- <div class="btn"><a href="">立即体验</a></div> -->
          <div class="sub-title"><em>产品规格</em></div>
          <ul>
            <li>
              <div class="name">订单数量</div>
              <div class="value orange">不限</div>
            </li>
            <li>
              <div class="name">客户数量</div>
              <div class="value orange">不限</div>
            </li>
            <li>
              <div class="name">供应商数量</div>
              <div class="value orange">不限</div>
            </li>
            <li>
              <div class="name">司机数量</div>
              <div class="value orange">不限</div>
            </li>
            <li>
              <div class="name">账户数量</div>
              <div class="value orange">不限</div>
            </li>
          </ul>
          <div class="sub-title"><em>平台功能</em></div>
          <ul>
            <li>
              <div class="name">货主端含电脑版和手机版</div>
              <div class="ico">
                <img src="@/assets/image/img16_1.png" alt="" />
              </div>
            </li>
            <li>
              <div class="name">物流端含电脑版和手机版</div>
              <div class="ico">
                <img src="@/assets/image/img16_1.png" alt="" />
              </div>
            </li>
            <li>
              <div class="name">司机端含手机版</div>
              <div class="ico">
                <img src="@/assets/image/img16_1.png" alt="" />
              </div>
            </li>
          </ul>
          <div class="sub-title"><em>产品服务</em></div>
          <ul>
            <li>
              <div class="name">业务顾问</div>
              <div class="ico">
                <img src="@/assets/image/img16_1.png" alt="" />
              </div>
            </li>
            <li>
              <div class="name">专属客服</div>
              <div class="ico">
                <img src="@/assets/image/img16_1.png" alt="" />
              </div>
            </li>
            <li>
              <div class="name">视频教程</div>
              <div class="ico">
                <img src="@/assets/image/img16_1.png" alt="" />
              </div>
            </li>
            <li>
              <div class="name">在线帮助</div>
              <div class="ico">
                <img src="@/assets/image/img16_1.png" alt="" />
              </div>
            </li>
            <li>
              <div class="name">成功经理</div>
              <div class="ico">
                <img src="@/assets/image/img16_1.png" alt="" />
              </div>
            </li>
          </ul>
        </div>
        <div class="list list-3 wow animate__animated animate__slideInUp">
          <div class="title">
            <h3>私有版</h3>
            <div>适用于中大型企业个性化定制</div>
          </div>
          <div class="cost">
            <h3>更灵活/更专业/更高效</h3>
          </div>
          <!-- <div class="btn"><a href="">立即体验</a></div> -->
          <div class="sub-title"><em>产品服务</em></div>
          <ul>
            <li>
              <div class="name">包含平台业务管理所有功能</div>
            </li>
            <li>
              <div class="name">数据隔离加密,安全稳定</div>
            </li>
            <li>
              <div class="name">独享云资源,性能更优</div>
            </li>
            <li>
              <div class="name">高可用性能架构,动态扩容</div>
            </li>
            <li>
              <div class="name">企业自主掌控,多维度管理</div>
            </li>
            <li>
              <div class="name">二级域名（需定制）</div>
            </li>
            <li>
              <div class="name">单点登录（需定制）</div>
            </li>
            <li>
              <div class="name">解决方案架构师服务</div>
            </li>
            <li>
              <div class="name">专业服务保障</div>
            </li>
          </ul>
          <div class="sub-title"><em>部署服务</em></div>
          <ul>
            <li>
              <div class="name">专属云部署（推荐）</div>
            </li>
            <li>
              <div class="name">本地部署</div>
            </li>
          </ul>
          <div class="sub-title"><em>高级服务</em></div>
          <ul>
            <li>
              <div class="name">二次开发服务</div>
            </li>
          </ul>
        </div>
      </div>
    </div>

    <div class="index-8">
      <div class="wal">
        <div class="index-title">
          <h2>10大服务保障,消除客户后顾之忧</h2>
          <p>匠心做产品,热心做服务</p>
        </div>
        <div class="list">
          <ul>
            <li>
              <div>7*24在线客服</div>
            </li>
            <li>
              <div>专属售前业务顾问</div>
            </li>
            <li>
              <div>专属客户成功经理</div>
            </li>
            <li>
              <div>专属行业模板数据</div>
            </li>
            <li>
              <div>物流辅助业务服务</div>
            </li>
            <li>
              <div>专属域名配置服务</div>
            </li>
            <li>
              <div>专属云备份服务</div>
            </li>
            <li>
              <div>专属云安全服务</div>
            </li>
            <li>
              <div>按客户需求持续升级</div>
            </li>
            <li>
              <div>开放平台数据接口</div>
            </li>
          </ul>
        </div>
      </div>
    </div>

    <div class="g-foot">
      <div class="wal">
        <div class="left">
          <div class="logo">
            <img src="@/assets/image/logo303.png" alt="" />
          </div>
          <div class="tel">
            <img src="@/assets/image/tel16.png" alt="" />
            <em>服务热线:</em>
            <span>0757-81068092</span>
          </div>
        </div>
        <div class="ewm">
          <ul>
            <li>
              <div class="img">
                <img src="@/assets/image/public_qr_code.jpg" alt="" />
              </div>
              <div class="name">运智易-物链云公众号</div>
            </li>
            <li>
              <div class="img">
                <img src="@/assets/image/program_qr_code.jpg" alt="" />
              </div>
              <div class="name">运智易-物链云小程序</div>
            </li>
          </ul>
        </div>
      </div>
    </div>
    <div class="g-foot-msg">
      <div class="wal">
        <p>Copyright © 2021-2020 51YZY.NET ALL Rights Reserved.</p>
        <p>
          <a href="https://beian.miit.gov.cn/" target="_blank"
            >粤ICP备2021076991号</a
          >
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import { WOW } from 'wowjs';
import Swiper from 'swiper';
import $ from 'jquery';
fontSize();
$(window).resize(function () {
  fontSize();
});

function fontSize() {
  var size;
  var winW = window.innerWidth;
  if (winW <= 1380 && winW > 800) {
    size = Math.round(winW / 13.8);
  } else if (winW <= 800) {
    size = Math.round(winW / 7.5);
    if (size > 65) {
      size = 65;
    }
  } else {
    size = 100;
  }
  $('html').css({
    'font-size': size + 'px'
  });
}

export default {
  name: 'Home',
  data(){
    return{
      videoShow: false
    }
  },
  components: {
  },
  mounted() {
    this.$nextTick(() => {
      //页面渲染完,在执行
      let wow = new WOW({
        live: false
      });
      wow.init();

      this.navTo();
      this.phoneNav();
      this.swiperFun();
    });
  },
  methods: {
    navTo: function () {
      let nav = $('.nav').find('li');
      nav.each(function (i) {
        $(this)
          .find('a')
          .click(function () {
            $('body,html')
              .stop(true, true)
              .animate(
                {
                  scrollTop: $('.page').eq(i).offset().top - $('.head').height()
                },
                300
              );
            $('body').removeClass('navShow');
          });
      });
      $(window).scroll(function () {
        $('.page').each(function (i) {
          if (
            $(window).scrollTop() >
            $(this).offset().top - $(window).height() / 2
          ) {
            nav.find('a').removeClass('on');
            nav.eq(i).find('a').addClass('on');
          }
        });
        //
        if ($(window).scrollTop() > 0) {
          $('.g-head').addClass('on');
        } else {
          $('.g-head').removeClass('on');
        }
      });
    },
    phoneNav: function () {
      $('.navA').click(function () {
        if ($('body').hasClass('navShow')) {
          $('body').removeClass('navShow');
        } else {
          $('body').addClass('navShow');
        }
      });
    },
    swiperFun: function () {
      let mySwiper = new Swiper('.index-5 .list', {
        loop: true,
        autoplay: 500,
        pagination: {
          el: '.swiper-pagination',
          type: 'bullets'
        }
      });
      $('.index-5')
        .find('.prev')
        .click(function () {
          mySwiper.slidePrev();
          return false;
        });
      $('.index-5')
        .find('.next')
        .click(function () {
          mySwiper.slideNext();
          return false;
        });
    }
  }
};
</script>

<style lang="scss" scoped>
@import 'public/static/css/index';
@import 'swiper/swiper.scss';
</style>
